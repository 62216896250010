import React from 'react';

import { Head } from 'src/components/head/head';
import { Layout } from 'src/components/layout/layout';
import { Gallery } from 'src/components/gallery/gallery';
import { Logo } from './_logo';
import thumbnail from './thumbnail.jpg';

import p1 from './pictures/1.jpg';
import p2 from './pictures/2.jpg';
import p3 from './pictures/3.jpg';
import p4 from './pictures/4.jpg';
import p5 from './pictures/5.jpg';
import p6 from './pictures/6.jpg';

export const Plants: React.FC = () => (
  <>
    <Head title="Houseplants" path="/plants" ogImage={thumbnail} />
    <Layout logo={<Logo />}>
      <h1 className="page-intro">The Pot & The Plant</h1>
      <p>
        Houseplants: collectible living tamagotchis that don’t stop growing and incidentally provide{' '}
        <em>great</em> decor. What’s not to love? I credit houseplants to being my gateway drug into
        nature (gardening, permaculture, and so forth).
      </p>
      <p>
        🚧 I’m working on turning this page into a catalogue of my current houseplants,
        Pokédex-style, but in the meanwhile, have a few curated pictures and some thoughts on my
        favorite houseplant species, as well as ones I perpetually murder.
      </p>
      <Gallery
        photos={[
          {
            src: p1,
            width: 4,
            height: 3,
            alt: '',
            tabIndex: 1,
            loading: 'lazy',
          },
          {
            src: p5,
            width: 2,
            height: 3,
            alt: '',
            tabIndex: 1,
            loading: 'lazy',
          },
          {
            src: p4,
            width: 4,
            height: 3,
            alt: '',
            tabIndex: 1,
            loading: 'lazy',
          },
          {
            src: p3,
            width: 16,
            height: 9,
            alt: '',
            tabIndex: 1,
            loading: 'lazy',
          },
          {
            src: p2,
            width: 16,
            height: 9,
            alt: '',
            tabIndex: 1,
            loading: 'lazy',
          },
          {
            src: p6,
            width: 4,
            height: 3,
            alt: '',
            tabIndex: 1,
            loading: 'lazy',
          },
        ]}
      />
      <h2>Favorite houseplant families</h2>
      <details>
        <summary>Peperomia</summary>
        <p>
          One of my absolute favorites. Gorgeous and incredibly varied, while remaining tolerant to
          under-watering, and smaller roots that don’t require frequent up-potting. In terms of
          showing stress, they tend drop leaves instead of getting brown/yellow tips which I prefer
          visually (although it can get annoying to clean up after depending on the plant - Ruby
          Cascade was a bit much for instance).
        </p>
      </details>
      <details>
        <summary>Philodendron</summary>
        <p>
          The tanks of the houseplant world - no houseplant collection is complete without them.
          They don’t tend to show stress or brown tips, and are ridiculously easy to care for. My
          heartleaf philodendron is currently overtaking my living room ceiling, with no signs of
          stopping. I’m a huge fan of the variety of this family as well - Neon, Micans, and Brasil
          should all be investigated, not to mention the Birkin.
        </p>
      </details>
      <details>
        <summary>Pothos</summary>
        <p>
          I’m not as big of a fan of pothos as I am philodendron - I find them to be a tad touchier
          and more prone to yellowing and brown tips - but nevertheless they’re still excellent to
          own and fairly easy to keep alive. I particularly love the coloration of N’joy pothos and
          marble queen pothos.
        </p>
        <p>
          One alternative to pothos that’s quite similar in terms of care and appearance is the
          Scindapsus family - while it’s less varied in appearance, it’s still quite a striking
          looking plant to own at least one of.
        </p>
      </details>
      <details>
        <summary>Tradescantia</summary>
        <p>
          A relatively new discovery of mine, I’m quite amazed by the growth and invincibility of
          this plant. It outgrows almost everything else I own and it can grow an entirely new plant
          from just a simple clipping - I’ve murdered and regrown the same zebrina multiple times
          now. It’s also happy as a clam in just water, which is fun.
        </p>
      </details>
      <details>
        <summary>Hoya</summary>
        <p>
          Another plant that works well with my usual under-watering regime and rarely shows
          complaints or stress in its leafs. The variegation in the Krimson Queen/Princess are fun,
          although I’ll admit I find the majority of its varieties somewhat same-y.
        </p>
      </details>
      <details>
        <summary>Sansevieria</summary>
        <p>
          It’s always nice to have at least one tall snake plant for the distinctive look, although
          there’s also more compact varieties available. It’s super hard to kill these except
          through overwatering. They do require high lighting to actually grow; I’ve had the same
          snake plant now that I’m pretty sure hasn’t really moved or changed for the past 2 years,
          but it hasn’t died or wilted either, so that’s fine by me.
        </p>
      </details>
      <details>
        <summary>Others</summary>
        <ul>
          <li>
            Cast Iron Plant
            <ul>
              <li>
                My first ever houseplant, that got me into the whole shebang. This plant is a super
                slow grower but is also indestructible - I spent the first year wondering if I was
                watering a fake plant. It has nice large foliage and is perfect for beginners,
                tolerating almost everything you throw at it.
              </li>
            </ul>
          </li>
          <li>
            ZZ plants
            <ul>
              <li>
                The only way to kill a ZZ plant is through overwatering, which I learnt the hard way
                with soggy non-succulent soil on my first normal ZZ. I now have a Raven ZZ and a
                dwarf ZZ Zenzi which are doing well though, and I love their looks.
              </li>
            </ul>
          </li>
          <li>
            African violets
            <ul>
              <li>
                I have a love/hate relationship with these. They do real well at first, and then
                just stop blooming for me and get super bushy?? They look (and feel!) kinda cool
                though when bushy so I’ve kept one lil guy around.
              </li>
            </ul>
          </li>
          <li>
            Succulents
            <ul>
              <li>
                The PNW (and my house specifically) doesn’t really get a whole lot of consistent
                natural sunlight, so I’ve mostly only had success with succulents under grow lights.
                I have a couple for the novelty, and they do well with my low watering schedule, but
                I’m not in love with them or anything. They’re okay.
              </li>
            </ul>
          </li>
        </ul>
      </details>
      <details>
        <summary>Plants I have murdered</summary>
        <ul>
          <li>
            Calathea
            <ul>
              <li>
                This is a common complaint, but I will openly admit to having absolutely killed
                every calathea I’ve dared to bring home within less than a week. Apparently my house
                is completely lacking in humidity.
              </li>
            </ul>
          </li>
          <li>
            Dracaena
            <ul>
              <li>
                I want to like dracaenas but in the end I just don’t. They show brown tips super
                easily and I honestly have no idea what they want or what it looks like when they’re
                doing well.
              </li>
            </ul>
          </li>
          <li>
            Spider plants
            <ul>
              <li>
                Spider plants hate my tap water or something - their tips always brown for me
                immediately and the plant ends up looking gross and sad. I’m a lazy shit who refuses
                to baby plants with distilled water or other BS, so I just kinda gave up spider
                plants (other than 1 that just refuses to die). BTW, cats love eating them.
              </li>
            </ul>
          </li>
          <li>
            Parlor palm
            <ul>
              <li>
                So to be fair it was my cat that decimated this plant, not me. Turns out cats
                fucking love eating palms, it’s like crack to them or something.
              </li>
            </ul>
          </li>
        </ul>
      </details>
      <h2>Care notes</h2>
      <p>
        The above plants are what work for me and appeal to my aesthetic sensibilities. I tend to
        choose plants that prefer less water to more water, are hard to kill, and tend to not show
        stress (brown/yellow tips). I am extremely lazy and hands-off and absolutely cannot keep
        fussy/high-humidity plants such as calatheas alive.
      </p>
      <p>
        If you are similarly low-effort when it comes to plants, I can strongly recommend the above
        named families, self-watering planters (ideally with wicking ropes), and putting your plants
        near plain ol’ LED lights. Thanks to bottom-watering planters, I typically water once a week
        at most during summers and once every 3 weeks in the winter.
      </p>
    </Layout>
  </>
);

export default Plants;
